.loginContainer {
  min-height: 100vh;
  position: relative;
  background: #2d3e4f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backgroundImage {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.login-form-forgot {
  display: inline-block;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.login-form-button {
  width: 100%;
}

.loginCard {
  -webkit-box-shadow: 2px 2px 80px -10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 2px 80px -10px rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 80px -10px rgba(0, 0, 0, 0.5);
}
